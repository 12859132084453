<template>
  <v-card
    :img="
      item.thumbnail && item.thumbnail.url ? item.thumbnail.url : highResImage
    "
    height="225"
    :width="width"
    style="border-radius: 10px !important"
    @click.stop="
      $router.safePush({
        name: 'detail',
        params: {
          id: item.id,
        },
      })
    "
  >
    <div class="overlay">
      <div
        v-if="item.specialDeals && !item.new && !item.insider"
        class="card-promo white--text"
      >
        {{ $t("detail.specialDeals") }}
      </div>
      <div
        v-if="item.new && !item.insider"
        style="position: absolute; top: 0px; left: 16px"
      >
        <v-img :src="require('@/assets/flag.png')" />
        <span class="flag-content-new Text-White-Text-4---12-Left-Align">{{
          $t("dashboard.new")
        }}</span>
      </div>
      <div v-if="item.insider" style="position: absolute; top: 0px; left: 16px">
        <v-img :src="require('@/assets/flag.png')" />
        <span class="Text-White-Text-4---12-Left-Align flag-content-insider">{{
          $t("dashboard.insider")
        }}</span>
      </div>
      <v-card-actions
        v-if="
          $store.state.profile.data &&
          $store.state.session.user &&
          !$store.state.isMiKY
        "
      >
        <v-spacer></v-spacer>
        <v-btn icon large @click.stop="toggleFavorite(item.id)"
          ><v-icon
            :color="
              find($store.state.profile.data.favorites, (fav) => {
                return fav === item.id;
              })
                ? 'red'
                : 'white'
            "
            >{{
              find($store.state.profile.data.favorites, (fav) => {
                return fav === item.id;
              })
                ? "mdi-heart"
                : "mdi-heart-outline"
            }}</v-icon
          >
        </v-btn>
      </v-card-actions>
      <div class="card-text white--text">
        <v-card-title
          class="py-0 Heading-White-H3-22-Left-Align"
          v-if="item.header"
          style="word-break: break-word"
          >{{ item.header }}</v-card-title
        >
        <v-card-text
          class="Text-White-Text-3---14-Left-Align py-0"
          v-if="item.subHeader"
          style="word-break: break-word"
          >{{ item.subHeader }}</v-card-text
        >
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import find from "lodash/find";
export default {
  methods: {
    ...mapActions("favorites", ["toggleFavorite"]),
    find,
  },

  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    width: {
      type: String,
      default: "100%",
    },
  },

  computed: {
    highResImage() {
      let imageUrl = this.item.affiliateImage;

      if (!imageUrl) {
        return require("../../assets/image_placeholder_small.png");
      }

      if (this.item.source === "reserve-out") {
        let imageSource = imageUrl.slice(0, 49);
        let imageName = imageUrl.split("/")[imageUrl.split("/").length - 1];
        let highResUrl = imageUrl;
        if (
          imageSource === "https://ro-images-reserveout.netdna-ssl.com/crop/"
        ) {
          highResUrl = imageSource + "1404x936/" + imageName;
        }
        return highResUrl;
      } else if (this.item.source === "getyourguide") {
        let imageName = imageUrl.substr(imageUrl.length - 7);
        let highResUrl = imageUrl;
        if (imageName === "131.jpg") {
          highResUrl = imageUrl.slice(0, imageUrl.length - 7) + "107.jpg";
        }
        return highResUrl;
      } else if (this.item.source === "musement") {
        let imageWidth = imageUrl.substr(imageUrl.length - 5);
        let highResUrl = imageUrl;
        if (imageWidth === "w=540") {
          highResUrl = imageUrl.slice(0, imageUrl.length - 5) + "w=1404";
        }
        return highResUrl;
      } else {
        return imageUrl;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.card-text {
  position: absolute;
  bottom: 10px;
  word-break: break-word;
}
.card-text * {
  line-height: 1.3;
}
.v-btn:focus:before,
.v-btn:hover:before {
  opacity: 0 !important;
}
.flag-content-new {
  position: absolute;
  bottom: 0;
  left: 35px;
  top: 3px;
  color: #fff;
  text-transform: uppercase;
}
.flag-content-insider {
  position: absolute;
  color: #fff;
  top: 0;
  text-transform: uppercase;
  left: 15px;
  top: 3px;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.46);
  width: 100%;
  height: 100%;
}
.card-promo {
  background-color: #ea9c06;
  font-family: Hind;
  font-size: 11px;
  text-transform: uppercase;
  font-stretch: 100%;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.1em;
  position: absolute;
  top: 15px;
  left: 16px;
  padding: 1px 6px;
}
</style>
