var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticStyle:{"border-radius":"10px !important"},attrs:{"img":_vm.item.thumbnail && _vm.item.thumbnail.url ? _vm.item.thumbnail.url : _vm.highResImage,"height":"225","width":_vm.width},on:{"click":function($event){$event.stopPropagation();return _vm.$router.safePush({
      name: 'detail',
      params: {
        id: _vm.item.id,
      },
    })}}},[_c('div',{staticClass:"overlay"},[(_vm.item.specialDeals && !_vm.item.new && !_vm.item.insider)?_c('div',{staticClass:"card-promo white--text"},[_vm._v(" "+_vm._s(_vm.$t("detail.specialDeals"))+" ")]):_vm._e(),(_vm.item.new && !_vm.item.insider)?_c('div',{staticStyle:{"position":"absolute","top":"0px","left":"16px"}},[_c('v-img',{attrs:{"src":require('@/assets/flag.png')}}),_c('span',{staticClass:"flag-content-new Text-White-Text-4---12-Left-Align"},[_vm._v(_vm._s(_vm.$t("dashboard.new")))])],1):_vm._e(),(_vm.item.insider)?_c('div',{staticStyle:{"position":"absolute","top":"0px","left":"16px"}},[_c('v-img',{attrs:{"src":require('@/assets/flag.png')}}),_c('span',{staticClass:"Text-White-Text-4---12-Left-Align flag-content-insider"},[_vm._v(_vm._s(_vm.$t("dashboard.insider")))])],1):_vm._e(),(
        _vm.$store.state.profile.data &&
        _vm.$store.state.session.user &&
        !_vm.$store.state.isMiKY
      )?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","large":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleFavorite(_vm.item.id)}}},[_c('v-icon',{attrs:{"color":_vm.find(_vm.$store.state.profile.data.favorites, function (fav) {
              return fav === _vm.item.id;
            })
              ? 'red'
              : 'white'}},[_vm._v(_vm._s(_vm.find(_vm.$store.state.profile.data.favorites, function (fav) { return fav === _vm.item.id; }) ? "mdi-heart" : "mdi-heart-outline"))])],1)],1):_vm._e(),_c('div',{staticClass:"card-text white--text"},[(_vm.item.header)?_c('v-card-title',{staticClass:"py-0 Heading-White-H3-22-Left-Align",staticStyle:{"word-break":"break-word"}},[_vm._v(_vm._s(_vm.item.header))]):_vm._e(),(_vm.item.subHeader)?_c('v-card-text',{staticClass:"Text-White-Text-3---14-Left-Align py-0",staticStyle:{"word-break":"break-word"}},[_vm._v(_vm._s(_vm.item.subHeader))]):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }